@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.badges {
  &--first & {
    &__discount {
      display: none;
    }
  }

  &--second & {
    &__recommended {
      display: none;
    }
  }

  @include minScreenSize(desktop) {
    &--first & {
      &__discount {
        display: block;
      }

      &__recommended {
        display: none;
      }
    }

    &--second & {
      &__discount {
        display: none;
      }

      &__recommended {
        display: flex;
      }
    }
  }

  &__recommended {
    margin-bottom: $spacing-micro;

    @include minScreenSize(desktop) {
      position: absolute;
      right: 0;
      top: -$spacing-quarck;
    }
  }

  &__discount {
    width: 100%;
    text-align: center;
    padding: $spacing-quarck;
    font-size: $font-size-xxs;
    line-height: $line-height-nano;

    @include minScreenSize(desktop) {
      font-size: $font-size-xs;
      line-height: $line-height-sm;
    }

    &--empty {
      min-height: $spacing-xs;
      background-color: $color-neutral-lightest;

      @include maxScreenSize(desktop) {
        display: none;
      }
    }
  }
}
